<template>
  <div class="about">

    <div class="load">
      <!-- <img src="http://one.welltell.cc/public/vue/img/logo2.jpg" alt=""> -->
    </div>
    <div class="lt">
      精彩加载中
    </div>
    <vue-loaders name="ball-beat"></vue-loaders>


  </div>
</template>
<script>
export default {
  name:'welcom',
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>
<style lang="scss">
.about{
  height: 100%;
  text-align: center;
  background: #e8e8e8;
}
.load{
  img{
    width: 100%;
  }
}
.lt{
  text-align: center;
  font-size: 24px;
  margin-top: 20px;
  line-height: 2;
}
</style>
